.bg-no{
    background: none !important;
}
.hell div:nth-child(3){
    border-radius: 1rem;
}
.hell div:nth-child(3)>div>div{
    background-color: black;
}
.rounded-not{
    border-radius: 0 !important;
}