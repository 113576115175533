div.login2-container {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
div.login2-container .overlay {
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0.2))
    no-repeat;
}
div.login2-container div.back-button-container {
  z-index: 3;
}
div.login2-container .login2-content {
  /* position: absolute; */
  top: 170.18px;
  z-index: 2;
  padding: 100px 10px;
}
div.login2-container .login2-content .logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;
}

div.login2-container div.login2-content .heading {
  margin-bottom: 40px;
  text-align: center;
  font-size: 40px;
  line-height: 40px;
}
div.login2-container div.login2-content .subtitle {
  color: var(--sec-color);
  margin-bottom: 200px;
  font-size: 16px;
}
div.login2-container div.login2-content .button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
div.login2-container div.login2-content .button {
  margin-bottom: 10px;
}
div.login2-container div.login2-content button.signup {
  background-color: var(--primary-color);
  color: #000;
}
div.login2-container div.login2-content button.signup span,
div.login2-container div.login2-content button a.login {
  font-family: "Inter";
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}
div.login2-container div.login2-content .face-id {
  display: block;
  margin-top: 20px;
  text-align: center;
  font-size: 13px;
  font-family: "Obviously", "serif";
  line-height: 18px;
  color: var(--primary-color);
}
