div.helloScreen-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.helloScreen-container .ProceedButton {
  width: 40vmin;
  height: 53px;
  border-radius: 30px;

  background-color: var(--backgroundColor-button);
  border: none;
  color: #fff;
  font-family: "Obviously", "serif";
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}
