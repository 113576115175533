div.signup3-container {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div.signup3-container div.back-button-container {
  z-index: 3;
}
div.signup3-container .signup3-content {
  z-index: 2;
}
div.signup3-container div.signup3-content .title {
  margin: 0px 0px 10px 10px;
  text-align: left;
}
div.signup3-container div.signup3-content .subtitle {
  margin: 0px 0px 40px 10px;
  text-align: left;
}
div.signup3-container div.signup3-content .form-container .input-field {
  display: block;
  margin: 0px auto 10px auto;
  /* height: 53px;
  font-family: "Inter";
  font-size: 15px;
  font-weight: 400;
  line-height: 18px; */

  text-align: left;
}
div.signup3-container
  div.signup3-content
  .form-container
  .input-field[type="date"] {
  -webkit-appearance: none; /* For Safari */
  appearance: none;
}
div.signup3-container div.signup3-content .form-container .date {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.signup3-container div.signup3-content .form-container .date:focus {
  border: none;
  outline: none;
}
/* div.signup3-container div.signup3-content .form-container .otp-msg {
  text-align: center;
  margin: 40px 0px 45px 0px;
  color: var(--sec-color);
  font-family: var(--playfair-display);
  font-size: 15px;

  font-weight: 400;
  line-height: 18px;
} */
div.signup3-container div.signup3-content .form-container .toggle-button {
  position: absolute;
  bottom: 13px;

  right: 22px;

  cursor: pointer;
}
.nation > div {
  border-radius: 50px !important;
  padding: 0 25px !important;
}
div.signup3-container
  div.signup3-content
  .form-container
  :where(.css-dev-only-do-not-override-vn1oyg).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  padding-left: 25px;
  font-family: "Obviously";
  font-size: 15px;
  line-height: 18px;
}
/* .react-datepicker__year-option {
  background-color: black;
}
.react-datepicker__year-option a {
  border-color: #ccc;
  border-style: solid;
  border-width: 0px 3px 3px 0;
  content: "";
  display: block;
  height: 9px;
  transform: rotate(45deg);
  width: 9px;
} */
.no-scroll {
  overflow: hidden;
  height: 100vh; /* Ensure it occupies the full height */
}
