div.login-container {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

div.login-container .overlay {
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0.2)) no-repeat;
}

div.login-container div.back-button-container {
  z-index: 3;
}

div.login-container .login-content {
  position: absolute;
  top: 170.18px;
  z-index: 2;
  padding: 10px;
}

div.login-container .login-content .logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;
}

.button-wrapper {
  display: flex;
  padding: 30px 0px;
  padding-top: 50px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}