div.forgot-container {
  /* height: 100vh; */
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* div.forgot-container .background-video {
  height: 100vh;
} */
div.forgot-container div.back-button-container {
  z-index: 3;
}
div.forgot-container .forgot-content {
  z-index: 2;
  /* position: absolute; */
  top: 170.18px;
  padding: 10px;
}
.forgot-container .forgot-content .title {
  margin-bottom: 30px;
}
.forgot-container .forgot-content .input-field {
  margin-bottom: 30px;
  width: 80vmin;
  display: block;
  margin: 0px auto 30px auto;
}
div.forgot-container .forgot-content .subtitle {
  margin: 0px 30px 40px 30px;
}

div.forgot-container .forgot-content .input-field::placeholder {
  text-align: center;
  color: #000;
  font-size: 20px;
}
div.forgot-container .forgot-content .button-container {
  margin-top: 300px;
}
