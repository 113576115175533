div.loginpage-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
div.loginpage-container div.back-button-container {
  z-index: 3;
}
div.loginpage-container .loginpage-content {
  /* position: absolute; */
  /* top: 170.18px; */
  margin-top: 50px;
  z-index: 2;
  padding: 7rem 0 3rem 0;
  min-height: 100vh;
}
div.loginpage-container div.loginpage-content .heading {
  margin-bottom: 30px;
}
div.loginpage-container div.loginpage-content .subtitle {
  margin: 0px 40px 40px 40px;
}
div.loginpage-container div.loginpage-content .input-field {
  width: 83vmin;
  display: block;
  margin: 0px auto 10px auto;
}
div.loginpage-container div.loginpage-content .submitbutton-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
div.loginpage-container div.loginpage-content .submitbutton-container .button {
  width: 70vmin;
  margin-top: 30px;
}

div.loginpage-container div.loginpage-content .forgot-link {
  font-family: var(--playfair-display);
  color: var(--sec-color);

  position: absolute;
  margin-top: 30px;
  left: 50%;

  transform: translate(-50%, -50%);

  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}
div.loginpage-container div.loginpage-content .footer {
  text-align: center;
  color: var(--primary-color);

  font-family: "Obviously", "serif";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, -50%);
}
div.loginpage-container div.loginpage-content .toggle-button {
  position: absolute;
  bottom: 10px;

  right: 40px;

  cursor: pointer;
}
