.custom-modal-class {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .ant-modal-confirm-btns > button > span[data-content="Cancel"] {
  order: 2;
}
.ant-modal-confirm-btns .ant-btn span[data-content="Log out"] {
  order: 1;
} */
:where(.css-dev-only-do-not-override-vn1oyg).ant-btn-primary {
  background-color: #b78600;
  border-radius: 25px;
}

.modalComp .styleModal {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}
