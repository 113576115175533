div.terms-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  height: 100vh;
}
div.terms-container .background-video {
  height: 100vh;
}
/* div.terms-container p,
div.terms-container h4 {
  color: var(--sec-color);
  margin: 10px 0px;
  font-size: 15px;
  font-weight: 200;
  line-height: 18px;
  font-family: "playfair", "serif";
  font-style: italic;
} */
div.terms-container h1.title {
  margin-top: 70px;
  color: var(--primary-color);
  /* font-size: 7vmin; */
  text-align: left;
  /* position: -webkit-sticky;
  top: 70px;
  position: sticky; */
  font-family: "Playfair Display";
  font-size: 28px;
  font-style: italic;
  font-weight: 400;
  line-height: 40px;
}

div.terms-container p.subtitle {
  text-align: left;
  margin-bottom: 30px;
  text-align: left;
}
.terms-content {
  height: 78vh;
  margin-left: 15px;
  position: relative;
  z-index: 2;
  line-height: 20px;
  overflow: auto;
  text-align: left;
  margin-top: 3rem;
  color: #e0dcdc;
}
.terms-container {
  overflow-y: scroll;
  padding-right: 10px;
  margin-bottom: 40px;
}

div.terms-container .back-button-container {
  z-index: 2;
}
.agree {
  padding: 10px;
  width: 100%;
  font-size: 15px;
  margin: 15px 0px;
  background-color: #fff;
  color: black;
}
div.terms-container div.terms-content .accepted {
  padding: 10px;
  width: 100%;
  font-size: 15px;
  margin: 15px 0px;
  background-color: rgb(85, 85, 230);
  color: #fff;
}
div.terms-container button.button {
  width: 70vmin;
  margin-top: 20px;
}
.new {
  z-index: 3 !important;
}
.section {
  text-align: left;
  padding: 1rem 0;
}
/* .terms-content h1 {
  font-size: 25px;
  color: #fff;
  margin-bottom: 40px;
} */
.terms-content .subtitle {
  padding: 1rem 0;
}
.terms-content .tNc,
.terms-content .tNc section {
  font-family: "Playfair Display";
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.terms-content .tNc h1 {
  font-family: "Playfair Display";
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-bottom: 25px;
}
.terms-content .tNc section h2 {
  margin-top: 20px;
}
.terms-content .tNc section p {
  margin-top: 20px;
}
