div.signup2-container {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* div.signup2-container .background-video {
  height: 100vh;
} */
div.signup2-container .signup2-content {
  z-index: 2;
  /* position: absolute; */
  top: 229px;
}
div.signup2-container div.back-button-container {
  z-index: 3;
}
.signup2-container .signup2-content .input-field {
  margin-bottom: 30px;
  width: 70vmin;
  display: block;
  margin: 0px auto 30px auto;
  font-size: 20px;
  height: 53px;
}
div.signup2-container .input-field::placeholder {
  text-align: center;
}
.signup2-container .signup2-content .title {
  margin-bottom: 30px;
}
div.signup2-container .signup2-content .subtitle,
div.signup2-container .signup2-content a {
  margin: 0px 10px 20px 10px;
}

div.signup2-container .signup2-content a {
  text-decoration: underline;
  color: var(--sec-color);
  font-style: italic;
  display: block;
  text-align: center;
  font-family: "playfair", "serif";
}
div.signup2-container button.button {
  margin-top: 50px;
}
.input-div {
  background-color: white;
  border-radius: 3rem;
  margin-bottom: 20px;
}
div.signup2-container .signup2-content .button-container {
  margin-top: 300px;
}
