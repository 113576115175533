.phone span span {
  border: none !important;
  padding-right: 0 !important;
  font-weight: bold !important;
}
.phone span input {
  border: none;
}
:where(.css-dev-only-do-not-override-vn1oyg).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: none;
}
:where(.css-dev-only-do-not-override-vn1oyg).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  font-size: 10px;
}
.profile .styleModal {
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(8px);
}

.profileComm .styleModal {
  background-color: #fff;
  height: 100vh;
  position: fixed;
}
