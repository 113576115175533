.custom-drawer.ant-drawer-open {
  transform: translateY(0); /* Drawer is fully visible */
}

.custom-drawer.ant-drawer-close {
  transform: translateY(100%); /* Drawer is hidden below */
}
.styleModal {
  background-color: black;
  padding: 10px;
  height: 90vh;
}
