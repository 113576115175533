* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #fff;
  --sec-color: #e0dcdc;
  --backgroundColor-button: #b78600;
}

@font-face {
  font-family: "Obviously";
  src: url("./assets/fonts/fonnts.com-Obviously.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

:where(.css-dev-only-do-not-override-1pg9a38).ant-radio-button-wrapper {
  height: 50px;
  text-align: center;
  padding-top: 10px;
}

:where(.css-dev-only-do-not-override-1pg9a38).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: 1px solid #d9d9d9;
  background: #ffffff;
  padding: 25px;
  font-size: 15px;
  border-radius: 30px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  font-family: "Obviously";
}

:where(.css-dev-only-do-not-override-1pg9a38).ant-select .ant-select-arrow {
  padding: 17px;
}

@font-face {
  font-family: "Obviously ";
  src: url("./assets/fonts/fonnts.com-Obviously.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* .ant-radio-group-solid
    :where(
      .css-dev-only-do-not-override-1pg9a38
    ).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: hsl(220, 3%, 77%);
    border-color: hsl(220, 3%, 77%);
  } */
.App {
  background-image: url("./assets/bg-main.jpg");
  position: relative;
  z-index: 1;
}

.qVaWx1ia4MK_oyPXz791 {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;

  transform: translate(-50%, -50%);

  object-fit: cover;
}

.video-background {
  position: fixed;
  /* Ensures the video stays in place even when scrolling */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
  /* Hides any overflow from the video */
  z-index: -1;
  /* Sends the video behind the content */
}
.video-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0.3))
    no-repeat;
  pointer-events: none; /* Ensure the overlay doesn't block interactions */
  z-index: 1; /* Ensure the gradient is above the video */
}
video {
  object-fit: contain;
  overflow-clip-margin: content-box;
  overflow: clip;
}

video {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

/* .background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
} */

/* .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0.3))
    no-repeat;
  pointer-events: none;
  z-index: 2;
} */
.heading {
  color: var(--primary-color);
  font-family: "playfair", "serif";
  line-height: 40px;
  font-size: 40px;
  font-style: italic;
  text-align: center;
}

.title {
  font-family: "playfair", "serif";
  font-size: 28px;
  font-style: italic;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  color: var(--primary-color);
}

.subtitle {
  font-family: "playfair", "serif";
  color: var(--sec-color);
  font-size: 15px;
  line-height: 18px;
  font-style: italic;
  text-align: center;
}

.button-container {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button {
  width: 75vmin;
  height: 53px;
  border-radius: 30px;

  background-color: var(--backgroundColor-button);
  border: none;
  color: var(--primary-color);
  font-family: "Obviously", "serif";
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}

/* a {
    text-decoration: none;
    color: var(--sec-color);
  } */
/* p {
    font-family: var(--playfair-display);
    color: var(--sec-color);
    font-weight: 100;
    font-size: 4vmin;
  } */
.input-field {
  width: 85vmin;
  height: 53px;
  border-radius: 30px;
  margin-top: 2%;
  padding: 25px;
  font-family: "Obviously", "serif";
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

/* .input-field::placeholder {
  } */
.back-button {
  margin-top: 20px;
  margin-bottom: 20px;
  align-self: flex-start;
  margin-left: 10px;
  border-radius: 50%;
  /* box-shadow: 0px 0px 8.4px 0px #00000040; */
}

.back-button-container {
  width: 100%;
  display: flex;
  /* justify-content: flex-start; */
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
}

.nav-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #08264a;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.nav-container svg {
  width: 2rem;
  height: 20px;
  color: white;
}

.home-icon {
  position: relative;
}

.home-icon:before {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 70px;
  height: 70px;
  background-color: #08264a;
  border-radius: 50%;
  z-index: -1;
}

.playfair-bold {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: bold;
}

.playfair {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: normal;
}

.obviously {
  font-family: Obviously;
}

.roboto {
  font-family: "Roboto", sans-serif;
  font-style: normal;
}

.carousel-container {
  display: flex;

  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 8px;
  padding: 10px;
}

.carousel-container::-webkit-scrollbar {
  display: none;
}

.navigation-bg-image {
  background-image: url("./assets/svg/navigation_bg.svg");
}

.bg-valiram {
  background-image: url("./assets/svg/card_bg.svg");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-valiram2 {
  background-image: url("./assets/svg/navigation_bg.svg");
}

.bg-mk {
  background-image: url("./assets/mkCardBg.png");
}

.bg-mk2 {
  background-image: url("./assets/mkcard2.png");
}

.bgBlack {
  background: #000;
}

.bg-grad {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 1) 100%
  );
}

.down {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

select:focus {
  outline: none;
}

.w-100 {
  width: 100%;
}

/* h1, h2, h3 {
    font-weight: bold;
  } */

.loading-div {
  position: fixed;
  inset: 0px;
  z-index: 9999;
  background: #f4f4f4;
  text-align: center;
  padding: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
}

video {
  -webkit-user-select: none;
  -webkit-transform: translateZ(0);
  overflow: hidden;
}

.text-capitalize {
  text-transform: capitalize;
}

/* .filterBtn:focus {
  background-color: #b5b5b5 !important;
  border: 1px solid #b78600 !important;
} */

.nav-container p {
  font-family: Obviously;
  font-size: 8px;
  font-weight: 550;
  line-height: 12.52px;
  text-align: center;
}

#fc_frame {
  bottom: 70px !important;
  right: 10px !important;
}

/* mobile input style */
.arrow {
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 6px;

  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.arrow.up {
  border-top: none;
  border-bottom: 4px solid #555;
}

/**/
:where(
    .css-dev-only-do-not-override-vn1oyg
  ).ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input)
  .ant-select-selector {
  background: #fff;
}

:where(
    .css-dev-only-do-not-override-vn1oyg
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
:where(
    .css-dev-only-do-not-override-vn1oyg
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  color: #000;
  padding-inline-end: 0px;
  font-size: 15px;
}

:where(
    .css-dev-only-do-not-override-vn1oyg
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
:where(
    .css-dev-only-do-not-override-vn1oyg
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  color: #000;
  padding-inline-end: 0px;
  font-size: 15px;
}

:where(.css-dev-only-do-not-override-vn1oyg).ant-select .ant-select-arrow {
  display: none !important;
}
.custom-select:focus {
  outline: none; /* Remove default outline */
  border: none; /* Optional: Remove border if necessary */
}

:where(.css-dev-only-do-not-override-vn1oyg).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  padding: 0px;
}

.sel {
  overflow: hidden !important;
}
.sel .ant-select-selector {
  width: 200px !important;
}
.sel .ant-select-selection-item {
  margin-left: 11px !important;
}
.select-no-toggle {
  /* display: none; */
  appearance: none; /* Removes the default styling */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
}
:where(.css-dev-only-do-not-override-vn1oyg).ant-drawer .ant-drawer-mask {
  background: none !important;
}
:where(.css-vn1oyg).ant-drawer .ant-drawer-mask {
  background: none !important;
}
/* :where(.css-vn1oyg).ant-select-outlined:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none !important;
} */
:where(.css-vn1oyg).ant-select .ant-select-arrow {
  display: none !important;
}
