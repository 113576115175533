.updatePassword-container {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
div.updatePassword-container .background-video {
  height: 100vh;
}

div.updatePassword-container div.back-button-container {
  z-index: 3;
}
div.updatePassword-container .updatePassword-content {
  z-index: 2;
}
.updatePassword-container .updatePassword-content .title {
  margin-bottom: 30px;
}
.updatePassword-container .updatePassword-content .input-field {
  margin-bottom: 30px;
  width: 80vmin;
  display: block;
  margin: 0px auto 30px auto;
}
div.updatePassword-container .updatePassword-content .subtitle {
  margin: 0px 50px 40px 50px;
}

div.updatePassword-container .updatePassword-content .button-container {
  margin-top: 200px;
}
div.updatePassword-container .updatePassword-content .toggle-button {
  position: absolute;
  bottom: 15px;

  right: 65px;

  cursor: pointer;
}
.custom-modal .ant-modal-content {
  background-color: #1a202c !important; /* Dark gray background */
  color: white !important; /* Text color */
}
