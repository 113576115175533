.carousel-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory; /* For smooth scrolling and snapping */
  gap: 8px; 
  padding: 10px;
}

.card {
  border-radius: 1rem;
  height: 12rem; 
  width: 9rem; 
  flex-shrink: 0;
  scroll-snap-align: start;
  color: white;
  text-align: center;
  /* background-size: cover; */
}
.carousel-container::-webkit-scrollbar {
  display: none;
}