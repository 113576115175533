div.signup-container {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* div.signup-container .background-video {
  height: 100vh;
} */
div.signup-container div.back-button-container {
  z-index: 3;
}
div.signup-container .signup-content {
  z-index: 2;
  /* position: absolute; */
  top: 229px;
}
div.signup-container form.signup-content .input-field {
  width: 70vmin;
  display: block;
  margin: 0px auto 10px auto;
  /* font-size: 20px;
  height: 53px; */
}
div.signup-container form.signup-content .input-field::placeholder {
  text-align: center;
  color: #000;
  font-size: 20px;
}
div.signup-container form.signup-content .title {
  margin-bottom: 50px;
  margin-top: 90px;
}
div.signup-container p.error {
  color: #ff0000;
  text-align: center;
  margin: 0px 40px 10px 40px;
  font-family: "playfair", "serif";

  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
div.signup-container form.signup-content .subtitle {
  margin: 80px 40px 80px 40px;
}
div.signup-container button.button {
  margin-top: 150px;
}
div.signup-container .signup-content .button-container {
  margin-top: 250px;
}
.phone-input-container {
  display: flex;
  align-items: center;
}
.ant-row-middle {
  justify-content: center;
}
.ant-select-show-search:where(
    .css-dev-only-do-not-override-vn1oyg
  ).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 0;
}
.country-code-select {
  border-right: none;
  z-index: 1;
}

.phone-number-input {
  border-radius: 0;
  margin-left: -1px;
  z-index: 0;
}
